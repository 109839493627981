import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import AnchorScroll from 'classes/AnchorScroll'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

class App {
  constructor() {
    this.AnchorScroll = new AnchorScroll()
    this.toggleMobileMenu()
    this.heroTitleAppear ()
    this.initNavigation ()
  }

  // モバイルのハンバーガーメニュー表示
  toggleMobileMenu () {
    const menuButton = document.querySelector('.header__menu__mobile')
    const mobileMenu = document.querySelector('.header__menu__mobile__open')
    const closeButton = document.querySelector('.header__menu__mobile__close')

    gsap.set(mobileMenu, { xPercent: 100})

    function disableScroll(event) {
      event.preventDefault();
    }

    menuButton.addEventListener('click', () => {
      let tl = gsap.timeline({
        defaults: {
          duration: 0.3,
          ease: 'Power4.out'
        }
      })
      tl.to(mobileMenu, {xPercent: 0})
      document.addEventListener('touchmove', disableScroll, { passive: false })
      document.body.classList.add('overflow-hidden')
    })

    closeButton.addEventListener('click', () => {
      let tl = gsap.timeline({
        defautls: {
          duration: 0.3,
          ease: 'Power4.out'
        }
      })
      tl.to(mobileMenu, {xPercent: 100})
      document.removeEventListener('touchmove', disableScroll, { passive: false })
      document.body.classList.remove('overflow-hidden')
    })
  }

  heroTitleAppear () {
    const heroTitle = document.querySelector('.title__appear')
    const topTitle = document.querySelector('.topTitle__appear')

    gsap.set(heroTitle, { opacity: 0, y: 20})
    gsap.set(topTitle, { opacity: 0, x: -30})

    window.addEventListener('load', (e) => {
      let tlTop =gsap.timeline({
        defautls: {
          duration: 0.3,
          ease: 'Power3.out'
        }
      })
      tlTop.to(topTitle, {
        opacity: 1,
        x: 0,
        duration: 1.2
      })
      
      
      let tl = gsap.timeline({
        defautls: {
          duration: 0.3,
          ease: 'Power3.out'
        }
      })
      tl.to(heroTitle, {
        opacity: 1,
        y: 0
      })
    })
  }

  initNavigation () {
    const mainNavLinks = gsap.utils.toArray('.nav-line')

    mainNavLinks.forEach(link => {
      link.addEventListener('mouseleave', e => {
        link.classList.add('animate-out')

        setTimeout(() => {
          link.classList.remove('animate-out')
        }, 300)
      })
    })
  }


}

new App()
